/**=====================
    3.2 Bookmark-app CSS Start
==========================**/
.modal-bookmark {
    .modal-body,
    .modal-header {
      padding: 30px;
    }
  }
  .bookmark-wrap {
    .details-bookmark {
      .row {
        >div {
          +div {
            padding-right: 15px !important;
          }
        }
      }
    }
    .left-bookmark {
      height: 700px;
      ul {
        li {
          button {
            border: none;
            cursor: pointer;
            &:focus {
              outline: none;
            }
          }
          svg {
            width: 18px;
            height: 18px;
            vertical-align: middle;
          }
          >.main-title {
            color: var(--theme-default);
            font-weight: 500;
            margin-bottom: 5px;
            display: block;
            font-size: 16px;
            span {
              a {
                &:hover {
                  background-color: $transparent-color;
                }
              }
            }
            svg {
              color: var(--dark-gray);
              stroke-width: 1px;
            }
          }
          a {
            >.title {
              text-transform: capitalize;
            }
          }
        }
      }
      &.task-sidebar{
        @media (max-width: 1366px) {
          height: auto;
        }   
      }
    }
    &.todo-wrap {
      .left-bookmark {
        height: unset;
      }
    }
  }
  .form-bookmark {
    a {
      text-transform: capitalize !important;
      color: var(--theme-default) !important;
      line-height: 1 !important;
      font-weight: 500;
      &:hover {
        background-color: $transparent-color !important;
      }
    }
    input {
      &#index_var {
        display: none;
      }
    }
    input,
    select {
      font-size: 14px;
    }
    .select2-container {
      .select2-selection--single {
        border-color: var(--avatar-preview-border);
        [data-theme="dark"] & {
          background: var(--body-color);
          border-color: var(--body-color);
        }
      }
    }
    .select2 {
      width: 100% !important;
    }
    .error {
      margin: 0 !important;
      font-size: 11px;
      color: $danger-color !important;
    }
    button {
      margin-top: 10px;
    }
  }
  @keyframes displayTransition {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .bookmark-tabcontent {
    .details-bookmark {
      height: auto;
      min-height: 359px;
      .bookmark-card {
        position: relative;
        &.card {
          .favourite-icon {
            padding: 3px;
            border-radius: 4px;
            background-color: rgba($black, 0.2);
            width: 30px;
            position: absolute;
            right: 15px;
            top: 15px;
            opacity: 0;
            i {
              color: $white;
              font-size: 16px;
              vertical-align: text-bottom;
            }
            &.favourite {
              i {
                color: var(--star-color);
              }
            }
          }
          .desciption-data {
            width: 100%;
            .content-general {
              display: none;
            }
          }
          .favourite-icon {
            transition: 0.5s;
          }
          .title-bookmark {
            text-align: left;
            padding: 15px;
            border-top: 1px solid $light-color;
            position: relative;
            h6 {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            p {
              animation: displayTransition 1s;
            }
            .hover-block {
              opacity: 0;
              position: absolute;
              width: 100%;
              padding: 15px;
              top: 30px;
              left: 0;
              ul {
                li {
                  display: inline-block;
                  +li {
                    margin-left: 5px;
                  }
                  svg {
                    width: 16px;
                    height: 16px;
                    color: var(--dark-gray);
                  }
                }
              }
              @media (max-width:767px) {
                padding-top: 0;
              }
            }
          }
          &:hover {
            box-shadow: 1px 4px 16px 3px rgba($black, 0.04);
            transition: 0.5s;
            .title-bookmark {
              p {
                opacity: 0;
              }
              .hover-block {
                opacity: 1;
                animation: displayTransition 2s;
              }
            }
            .favourite-icon {
              opacity: 1;
            }
          }
        }
      }
      &.list-bookmark {
        .row {
          >div {
            flex: 0 0 100%;
            max-width: 100%
          }
        }
        .bookmark-card {
          .desciption-data {
            .content-general {
              display: block;
            }
          }
          .details-website {
            display: flex;
            align-items: center;
            .title-bookmark {
              p {
                opacity: 1;
              }
              .hover-block {
                position: absolute;
                right: 30px;
                opacity: 1;
                text-align: right;
              }
            }
            &:hover {
              .title-bookmark {
                .hover-block {
                  opacity: 1;
                  animation: displayTransition 0s;
                }
              }
            }
          }
          &.card {
            .title-bookmark {
              border-top: none;
              p,
              h6 {
                margin-top: 0;
                display: inline-block;
              }
              h6 {
                position: relative;
                padding-right: 15px;
                &::after {
                  position: absolute;
                  content: '-';
                  right: 0;
                  color: var(--dark-gray);
                }
              }
              .content-general {
                margin-top: 5px;
                p {
                  width: 68%;
                  margin-right: 20px;
                }
              }
            }
          }
          img {
            width: 20%;
          }
        }
      }
    }
    .tab-content {
      .card {
        .d-flex {
          justify-content: space-between;
          align-items: center;
          svg {
            width: 18px;
            height: 18px;
            vertical-align: middle;
          }
          ul {
            li {
              display: inline-block;
              +li {
                margin-left: 5px;
              }
            }
          }
        }
      }
      .tab-pane {
        >.card {
          box-shadow: none;
        }
      }
    }
  }
  /**==responsive==**/
  @media only screen and (max-width: 1660px) {
    .bookmark-tabcontent {
      .details-bookmark {
        &.list-bookmark {
          .bookmark-card {
            &.card {
              .title-bookmark {
                .content-general {
                  p {
                    display: none;
                  }
                }
                .hover-block {
                  margin-top: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1366px) {
    .bookmark-wrap {
      .left-bookmark {
        ul {
          li {
            .btn-block {
              svg {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1366px) {
    .bookmark-tabcontent {
      .details-bookmark {
        &.list-bookmark {
          .bookmark-card {
            img {
              width: 40%;
            }
            &.card {
              .title-bookmark {
                .hover-block {
                  top: 80px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .bookmark-wrap {
      .left-bookmark {
        height: auto;
      }
    }
    .bookmark-tabcontent {
      .details-bookmark {
        min-height: auto;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .modal-bookmark {
      .modal-body,
      .modal-header {
        padding: 20px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .bookmark-tabcontent {
      .details-bookmark {
        &.list-bookmark {
          .bookmark-card {
            .details-website {
              .title-bookmark {
                p {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 172px;
                }
              }
              &:hover {
                .title-bookmark {
                  .hover-block {
                    p {
                      display: none;
                    }
                    ul {
                      position: relative;
                      right: unset;
                      top: unset;
                    }
                  }
                }
              }
            }
            img {
              width: 45%;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .bookmark-tabcontent {
      .details-bookmark {
        &.list-bookmark {
          .bookmark-card {
            img {
              width: 100%;
            }
            .details-website {
              display: block;
              .title-bookmark {
                padding-left: 15px;
                .hover-block {
                  position: relative;
                  right: unset;
                  text-align: left;
                  top: unset;
                  padding: 0;
                }
                .content-general {
                  display: none;
                }
                p,
                h6 {
                  display: block;
                }
                h6 {
                  &::after {
                    display: none;
                  }
                }
                p {
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
    }
    .modal-bookmark {
      .modal-body,
      .modal-header {
        padding: 15px;
      }
    }
  }
  /**=====================
    3.2 Bookmark-app CSS Ends
  ==========================**/