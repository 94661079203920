/**=====================
     Pagination css start
==========================**/
// pagination color
@each $key, $val in $colors {
  .pagination-#{$key} {
    .page-item{
      .page-link{
        color: $val;
      }
    }
  }
}
// pagination color
@each $key, $val in $colors {
  .pagination-border-#{$key} {
    .page-item{
      &.disabled{
        >.page-link {
          border-color: $val !important;
          background-color: transparent;
        }
      }
      .page-link{
        border: 1px solid $val;
        &:hover , &:active {
          background-color: $val;
          color: var(--white);
        }
        &:focus {
          box-shadow: none;
          background-color: rgba($val , 0.2);
          color: $val;
        }
        span{
          color: $val;
        }
      }
      &.active {
        .page-link {
          color: var(--white);
          background-color: $val;
        }
      }
    }
  }
}
.pagination {
  .page-item {
    &:active {
      background-color: unset;
    }
    .page-link {
      &.rounded-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
      }
    }
  }
}
// hand pagination
.hand-pagination{
  main {
    padding: 4rem 4rem 20rem; padding: 0 0 15rem;
    width: 100%; height: 80vh;
    box-sizing: border-box; overflow: hidden;
    display: flex; flex-flow: column nowrap; justify-content: flex-end;
  }
  #pagination-container {
    box-sizing: border-box;
    width: 100%;
    border: solid 1px red; border: none;
    position: relative;
    display: flex; flex-flow: row nowrap; justify-content: center; align-items: center;
    #indicator {
      width: 3rem; height: 3rem;
      border-radius: 50%; background-color: $warning-color; visibility: hidden;
      position: absolute; top: 50%; transform: translateY(-50%); left: 0; z-index: -1;
    }
    a {
      margin: 0 1.5rem 0 0; padding: .75rem 0 0; box-sizing: border-box; 
      border-radius: 50%;
      width: 3rem; height: 3rem;
      display: block;
      font-size: 1.5rem; color: var(--theme-default); text-decoration: none; font-weight: 700; 
      text-align: center; line-height: 100%;
      &:last-of-type {margin-right: 0;}
    }
    #hand-svg {
      width: 350px; height: 450px; box-sizing: border-box;
      position: absolute; top: 0; left: 0; z-index: -1;
      visibility: hidden;
      pointer-events: none;
    }
  }
}
.disabled {
	>.page-link {
    [data-theme="dark"] & {
    	border-color: var(--theme-default);
      background-color: transparent;
      }
	}
}
/**=====================
     Pagination css Ends
==========================**/