/**=====================
     color css start
==========================**/
@each $circle-name,
$circle-color in (primary, $primary-color),
(secondary, $secondary-color),
(tertiary, $tertiary-color),
(success, $success-color),
(warning, $warning-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, var(--body-font-color)) {
  .circle-dot-#{$circle-name} {
    width: 16px;
    height: 16px;
    border-radius: 10px;
    border: var(--light-border);
    @include flex_common;
    span {
      width: 8px;
      height: 8px;
      background-color: $circle-color;
      border-radius: 10px;
    }
  }
}
@each $font-name,
$font-color in (primary, $primary-color),
(secondary, $secondary-color),
(tertiary, $tertiary-color),
(success, $success-color),
(warning, $warning-color),
(danger, $danger-color),
(info, $info-color),
(light, var(--light-font)),
(dark, var(--body-font-color)) {
  .font-#{$font-name} {
    color: $font-color !important;
  }
  .stroke-#{$font-name} {
    stroke: $font-color !important;
  }
  .fill-#{$font-name} {
    fill: $font-color;
  }
  .toast-#{$font-name} {
    color: $font-color;
  }
}
.stroke-white {
  stroke: var(--white);
}
.font-dark {
  [data-theme="dark"] & {
    color: var(--light-font) !important;
  }
}
@each $bg-name,
$bg-color in (primary, $primary-color),
(secondary, $secondary-color),
(tertiary, $tertiary-color),
(success, $success-color),
(warning, $warning-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color) {
  .bg-#{$bg-name} {
    background-color: $bg-color !important;
    color: var(--white);
    span {
      [data-theme="dark"] & {
        color: var(--light-font) !important;
      }
    }
  }
  .bg-light-#{$bg-name} {
    background-color: rgba($bg-color, 0.1) !important;
    color: $bg-color !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $bg-color !important;
    }
    span {
      [data-theme="dark"] & {
        color: $bg-color !important;
      }
    }
    p {
      [data-theme="dark"] & {
        color: $bg-color !important;
      }
    }
  }
  .dote-#{$bg-name} {
    background-color: $bg-color;
    outline: 5px solid rgba($bg-color, 0.25);
    height: 6px;
  }
}
.bg-light-light {
  background-color: rgba($light-color, 0.6) !important;
  color: var(--body-font-color);
  [data-theme="dark"] & {
    background-color: var(--body-color) !important;
  }
}
.bg-light-dark {
  background-color: rgba($dark-color, 0.2) !important;
  color: var(--body-font-color);
}
.bg-light {
  color: var(--body-font-color);
  [data-theme="dark"] & {
    background-color: var(--body-color) !important;
  }
}
$colors: (primary: $primary-color,
  secondary: $secondary-color,
  tertiary: $tertiary-color,
  warning: $warning-color,
  info: $info-color,
  success: $success-color,
  danger: $danger-color,
  light: $light-color,
  dark: $dark-color);
@each $key,
$val in $colors {
  .bg-common-#{$key} {
    width: 42px;
    height: 40px;
    border-radius: 11px;
    background-color: rgba($val, 0.1);
    border: 1px solid $val;
    @include flex_common;
    svg {
      stroke: $val;
    }
  }
}
/**=====================
     color css end
==========================**/