.choices__inner {
  border: none;
  background: var(--light-color);
}

.choices__list[aria-expanded] {
  [data-theme="dark"] & {
    background: var(--body-color);
    border-color: var(--solid-border);
  }
}

.choices[data-type*=select-one] {
  .choices__input {
    [data-theme="dark"] & {
      background: var(--body-color);
      border-color: var(--solid-border);
    }
  }
}

.choices__input {
  background: var(--light-color);

  [data-theme="dark"] & {
    background: var(--body-color);
  }
}

.choices[data-type*=select-one] {
  &::after {
    [data-theme="dark"] & {
      border-color: var(--solid-border) transparent transparent;
    }
  }
}

.choices__list--dropdown {
  .choices__item--selectable.is-highlighted {
    [data-theme="dark"] & {
      background-color: rgba($primary-color, 0.1);
    }
  }
}

.choices__list[aria-expanded] {
  .choices__item--selectable.is-highlighted {
    [data-theme="dark"] & {
      background-color: rgba($primary-color, 0.1);
    }
  }
}

.choices__heading {
  [data-theme="dark"] & {
    border-color: var(--solid-border);
  }
}

.theme-form {
  .form-control {
    &::placeholder {
      [data-theme="dark"] & {
        color: var(--light-font);
      }
    }
  }
}

.choices__item {
  &.choices__item--selectable {
    [data-theme="dark"] & {
      color: var(--body-font-color);
    }
  }
}

.choices__list--multiple {
  .choices__item {
    background-color: var(--theme-default);
    border: 1px solid var(--theme-default);
    color: $white;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    padding: 3px 12px;
    margin-top: 2px;
  }
}

.choices__heading {
  font-weight: 700;
  color: var(--bs-heading-color);
}

.choices[data-type*=text] {
  .choices__button {
    margin-bottom: 0;
    border-left: 0;
  }
}

.choices[data-type*=select-multiple] {
  .choices__button {
    margin-bottom: 0;
    border-left: 0;
  }
}