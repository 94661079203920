/**=====================
    Image Cropper css Start
==========================**/
.img-container,
.img-preview {
    background-color: var(--light-color);
    text-align: center;
    width: 100%;
}
.img-container {
    margin-bottom: 1rem;
    max-height: 497px;
    min-height: 200px;
    .cropper-bg {
        width: 100% !important;
    }
}
.img-container {
    min-height: 497px;
}
.img-container>img {
    max-width: 100%;
}
.docs-preview {
    margin-right: -1rem;
}
.img-preview {
    float: left;
    margin-bottom: .5rem;
    margin-right: .5rem;
    overflow: hidden;
}
.img-preview>img {
    max-width: 100%;
}
.img-cropper {
    label.btn {
        margin: 0;
        @media only screen and (max-width: 360px) {
            padding: 0.375rem 0.2rem;
        }
    }
}
.img-cropper {
    #putData {
        margin-bottom: 0;
    }
    .img-container {
        min-height: auto;
        margin-bottom: 0;
    }
    .docs-data {
        >.input-group {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .docs-preview {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
.preview-lg {
    height: 9rem;
    width: 16rem;
}
.preview-md {
    height: 4.5rem;
    width: 8rem;
}
.preview-sm {
    height: 2.25rem;
    width: 4rem;
}
.preview-xs {
    height: 1.125rem;
    margin-right: 0;
    width: 2rem;
}
.docs-data>.input-group {
    margin-bottom: 10px;
}
.docs-data .input-group-prepend .input-group-text {
    min-width: 4rem;
}
.docs-data .input-group-append .input-group-text {
    min-width: 3rem;
}
.docs-buttons>.btn,
.docs-buttons>.btn-group,
.docs-buttons>.form-control {
    margin-bottom: 10px;
    margin-right: 10px;
}
.docs-toggles>.btn,
.docs-toggles>.btn-group,
.docs-toggles>.dropdown {
    margin-bottom: .5rem;
}
.docs-tooltip {
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
    @media only screen and (max-width: 360px) {
        font-size: 11px;
    }
}
.docs-tooltip>.icon {
    margin: 0 -.25rem;
    vertical-align: top;
}
.tooltip-inner {
    white-space: normal;
}
.btn-upload .tooltip-inner,
.btn-toggle .tooltip-inner {
    white-space: nowrap;
}
.btn-toggle {
    padding: .5rem;
}
.btn-toggle>.docs-tooltip {
    margin: -.5rem;
    padding: .5rem;
}
.btn-group-crop {
    margin-right: 5px !important;
}
.btn-group-crop>.btn {
    padding-left: .5rem;
    padding-right: .5rem;
}
.btn-group-crop .docs-tooltip {
    margin-left: -.5rem;
    margin-right: -.5rem;
    padding-left: .5rem;
    padding-right: .5rem;
}
.docs-options .dropdown-menu {
    width: 100%;
}
.docs-options .dropdown-menu>li {
    font-size: .875rem;
    padding: .125rem 1rem;
}
.docs-options .dropdown-menu .form-check-label {
    display: block;
}
.docs-cropped .modal-body {
    text-align: center;
}
.docs-cropped .modal-body>img,
.docs-cropped .modal-body>canvas {
    max-width: 100%;
}
.docs-buttons {
    .btn {
        padding: 6px 16px;
    }
}
.circle-image {
    .cropper-view-box,
    .cropper-face {
        border-radius: 50%;
    }
    #result {
        img {
            width: 160px;
            height: 160px;
            margin-top: 12px;
        }
    }
}
.docs-buttons {
    @media screen and (max-width: 1580px) and (min-width: 1200px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.docs-toggles {
    @media only screen and (max-width: 1366px) {
        margin-top: 8px;
    }
    @media screen and (max-width: 1580px) and (min-width: 1200px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .btn {
        @media only screen and (max-width: 360px) {
            padding: 0.375rem 0.2rem;
        }
    }
}
.docs-toggles {
    .dropdown {
        .dropdown-toggle {
            width: auto;
        }
    }
}
.input-group-text{
    [data-theme="dark"] & {
       background-color: var(--card-color);
     }
}
/**=====================
    Image Cropper css End
==========================**/