/**=====================
    3.7 Comingsoon CSS Start
==========================**/
.comingsoon-bgimg {
  background: url(../../images/other-images/coming-soon-bg.jpg);
  background-position: bottom;
  background-size: cover;
  [data-theme="dark"] & {
    background-blend-mode: overlay;
    background-color: rgba($black, 82%);
  }
}
.comingsoon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 0;
  [data-theme="dark"] & {
    background-blend-mode: overlay;
    background-color: rgba($black, 83%);
  }
  .comingsoon-inner {
    h5 {
      font-size: 18px;
      letter-spacing: 1px;
      color: var(--body-font-color);
      font-weight: 500;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .countdown {
      padding: 30px 0;
      border-top: 1px solid var(--solid-border);
      border-bottom: 1px solid var(--solid-border);
      .time {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: $white;
        font-weight: 500;
        width: 100px;
        height: 100px;
        font-size: 36px;
        background: var(--theme-default);
      }
      .title {
        padding-top: 13px;
        font-size: 14px;
        font-weight: 400;
        color: var(--body-font-color);
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      ul li {
        display: inline-block;
        text-transform: uppercase;
        margin: 0 20px;
        span {
          display: block;
        }
      }
    }
    .for-dark{
      margin: 0 auto;
    }
  }
  video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
  }
}
@media only screen and (max-width: 991.98px) {
  .comingsoon {
    .comingsoon-inner {
      .countdown {
        ul {
          li {
            .time {
              width: 80px;
              height: 80px;
              font-size: 26px;
            }
            .title {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 575.98px) {
  .comingsoon {
    .comingsoon-inner {
      .countdown {
        padding: 20px 0px;
        ul {
          li {
            margin: 0 7px;
            .time {
              width: 45px;
              height: 45px;
              line-height: 45px;
              font-size: 16px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
/**=====================
  3.7 Comingsoon CSS Ends
==========================**/