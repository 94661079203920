/**=====================
	3.30 Megaoption CSS Start
==========================**/
.mega-inline {
    margin-bottom: -30px;
    display: flex;
    .card {
        box-shadow: var(--card-box-shadow);
    }
    .mega-title-badge {
        display: table;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .d-flex {
        .radio {
            margin-left: 3px;
        }
    }
    @media (max-width: 575px) {
        margin-bottom: 0;
    }
}
.mega-vertical {
    display: flex;
    flex-wrap: wrap;
    .card {
        box-shadow: var(--card-box-shadow);
    }
    .mega-title-badge {
        display: table;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .d-flex {
        .radio {
            margin-left: 3px;
        }
    }
}
.mega-title {
    font-weight: 600;
}
.mega-horizontal {
    display: flex;
    flex-wrap: wrap;
    .card {
        box-shadow: var(--card-box-shadow);
    }
    .mega-title-badge {
        display: table;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .d-flex {
        .radio {
            margin-left: 3px;
        }
    }
}
.plain-style {
    .card {
        box-shadow: none;
        border: none !important;
    }
}
.border-style {
    .card {
        box-shadow: none;
        border: 1px solid $light-gray !important;
    }
}
.offer-style {
    .card {
        box-shadow: none;
        border: var(--light-border);
    }
}
.rating-star-wrapper {
    color: $rating-star;
}
.card-wrapper {
    .payment-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
        margin-bottom: 16px;
        border-bottom: var(--light-border);
        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: unset;
        }
        .payment-second {
            img {
                width: 50px;
            }
            .mega-icons {
                width: 20px;
                height: 20px;
            }
        }
    }
}
.main-upgrade {
    margin: 0 auto;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >div {
        &:first-child {
            margin-bottom: 20px;
        }
    }
    i {
        font-size: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
        display: block;
        color: var(--theme-default);
    }
    p {
        line-height: 1.4;
    }
    .variation-box {
        gap: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width:768px) and (max-width:1200px) {
            display: grid;
            grid-template-columns: auto auto;
            gap: 18px;
        }
        @media (max-width:700px) {
            display: grid;
            grid-template-columns: auto auto;
            gap: 18px;
        }
        @media (max-width:380px) {
            grid-template-columns: unset;
        }
        .selection-box {
            position: relative;
            height: 100px;
            border: 1px dashed var(--theme-default);
            min-width: 125px;
            border-radius: 15px;
            @media (min-width:1200px) and (max-width:1920px) {
                min-width: calc(110px + (125 - 110) * ((100vw - 1200px) / (1920 - 1200)));
            }
            .custom--mega-checkbox {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 25px;
                border-radius: 10px;
                transition: .5s ease;
            }
            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 118px;
                height: 96px;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
}
.megaoptions-border-space-sm {
    .d-flex {
        .radio {
            label {
                padding-left: 18px;
            }
        }
        .flex-grow-1 {
            .list-behavior-1 {
                @media (min-width:618px) and (max-width:1199px) {
                    flex-direction: row;
                    gap: 18px;
                }
                @media (max-width:618px) {
                    flex-direction: column;
                    gap: 12px;
                }
                .flex-grow-1 {
                    margin-top: -6px;
                    @media (max-width:618px) {
                        margin-left: 0px;
                    }
                }
                &:first-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.megaoption-space,
.megaoptions-border-space-sm {
    .badge {
        &.pull-right {
            margin-bottom: 12px;
        }
    }
}
.mega-option {
    .d-flex {
        label {
            font-weight: 600;
        }
    }
}
.megaoptions-border-space-sm {
    .flex-grow-1 {
        .list-behavior-1 {
            &:first-child {
                margin-bottom: 0;
            }
            @media (max-width: 618px) {
                flex-direction: column;
                gap: 12px;
                align-items: flex-start;
            }
        }
    }
}
/**=====================
    3.30 Megaoption CSS Ends
==========================**/