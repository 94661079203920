/**=====================
    Tour css start
==========================**/
.tg-dialog{
    .tg-dialog-header{
        padding: 15px 15px 0;
    }
    .tg-dialog-body{
        padding: 15px;
    }
    .tg-dialog-footer{
        padding: 15px;
        button.tg-dialog-btn{
            background-color: var(--theme-default);
            color: $white;
            border: $primary-color;
            &:hover{
                background-color: var(--theme-default);
                color: $white;
                border: $primary-color;
            }
        }
    }
    .tg-dialog-dots{
        > span.tg-dot.tg-dot-active{
            background-color: var(--theme-default);
        }
    }
} 
.flex-grow-1 {
    .tour-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        .tour-dot {
            font-size: 5px;
        }
    }
}
.tour-blog {
    height: 350px;
    @media (max-width:992px) {
        height: calc(200px + (350 - 200) * ((100vw - 320px) / (992 - 320)));
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.info {
    h6 {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
    span {
        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
        color: var(--light-font);
    }
}
.tour-email {
    display: flex;
    flex-direction: column;
    @media (max-width:576px) {
        align-items: center;
    }
}
.block-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.6 !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.6px;
}
.profile-img-style {
    h5 {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.6;
    }
}
.list-inline {
    .list-inline-item {
        &.b-r-gray {
            border-right: var(--light-border);
        }
    }
}
.social-tour {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 26px;
    .list-inline {
        display: flex;
        justify-content: center;
        gap: calc(12px + (26 - 12) * ((100vw - 320px) / (1920 - 320)));
        .list-inline-item {
            margin-right: 0;
            a {
                padding: 0px;
                i {
                    font-size: 18px;
                }
            }
        }
        @media (max-width: 599px) {
            display: none;
        }
    }
    .float-sm-end {
        min-width: 81px;
    }
}
.tour-mb-space {
    margin-bottom: calc(2px + (8 - 2) * ((100vw - 320px) / (1920 - 320)));
}
.like-comment {
    .list-inline {
        justify-content: flex-start;
        @media (max-width:324px) {
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 8px;
        }
    }
}
/**=====================
    Tour css end
==========================**/